@import '../../../assets/global-style/mixin';

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;

    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-white);
    text-decoration: none;
    text-align: center;

    background-color: var(--color-blue);
    border: 1px solid var(--color-blue);
    border-radius: 36px;

    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: 0.3s;
    -webkit-appearance: none; //убирает дефолтные стили в ios

    @include before_480 {
        padding: 8px 16px;
    }

    &__flex {
        display: flex;
        align-items: center;
    }

    &__el {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    &__icon {
        fill: var(--color-white);
        transition: 0.3s;
    }

    &:hover {
        color: var(--color-blue);
        background-color: var(--color-white);

        .button__icon {
            fill: var(--color-blue);
        }
    }

    &:active {
        opacity: 0.5;
        transition: 0.1s;
    }

    &:focus {
        opacity: 0.9;
        transition: 0.1s;
    }

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    &--inverted {
        color: var(--color-blue);
        background-color: transparent;

        .button__icon {
            fill: var(--color-blue);
        }

        &:hover {
            color: var(--color-white);
            background-color: var(--color-blue);

            .button__icon {
                fill: var(--color-white);
            }
        }

        &.is-active {
            color: var(--color-white);
            background-color: var(--color-blue);

            .button__icon {
                fill: var(--color-white);
            }
        }
    }

    &--uppercase {
        text-transform: uppercase;
    }
}

@import '../../components/structure/Grid/grid';
@import '../../components/structure/Header/header';
@import '../../components/structure/Main/main';
@import '../../components/structure/Wrapper/wrapper';
@import '../../components/structure/Footer/footer';
@import '../../components/buttons/Button/button.scss';
@import '../../components/typography/Title/Title.scss';
@import '../../components/typography/Text/Text.scss';
@import '../../components/other/Burger/burger.scss';

// Для страничке с видео
.lesson__video {
    display: block;
    margin: 0 auto 48px;

    @include before_1024 {
        aspect-ratio: 16 / 9;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@import '../../../assets/global-style/mixin';

.title {
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 500;
    color: var(--color-black);
    text-transform: uppercase;

    @include before_768 {
        margin-bottom: 24px;
        font-size: 24px;
    }

    &--center {
        text-align: center;
    }
}

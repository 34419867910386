@import '../../../assets/global-style/mixin';

.burger {
    position: relative;
    display: none;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 50%;
    flex-shrink: 0;
    cursor: pointer;

    @include before_1024 {
        display: block;
    }
}

.burger__strip,
.burger__strip:before,
.burger__strip:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -8px;
    width: 16px;
    height: 2px;
    background-color: var(--color-blue);
}

.burger__strip:before,
.burger__strip:after {
    content: '';
    transition: 0.3s;
}

.burger__strip:before {
    transform: translateY(-5px);
}

.burger__strip:after {
    transform: translateY(5px);
}

.burger--active .burger__strip {
    height: 0;
}

.burger--active .burger__strip:before {
    transform: rotate(45deg);
}

.burger--active .burger__strip:after {
    transform: rotate(-45deg);
}

@import '../../../assets/global-style/mixin';

.text {
    color: var(--color-gray);

    &--fs24 {
        font-size: 24px;
    }

    &--white {
        color: var(--color-white);
    }
}

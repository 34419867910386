@import '../../../assets/global-style/mixin';

.header {
    padding-top: 55px;
    margin-bottom: 40px;

    @include before_480 {
        padding-top: 24px;
    }

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include before_480 {
            flex-direction: column;
            align-items: stretch;
        }
    }

    &__auth {
        display: flex;
        gap: 20px;
    }

    &__menu {
        display: flex;
        align-items: center;
        gap: 40px;

        @include before_1200 {
            gap: 24px;
        }
    }

    &__menu-list {
        display: flex;
        align-items: center;
        gap: 45px;

        @include before_1200 {
            gap: 16px;
        }

        @include before_480 {
            flex-direction: column;
            align-items: center;
        }

        &--desktop {
            @include before_1024 {
                display: none;
            }
        }
    }

    &__menu-link {
        font-size: 16px;
        color: var(--color-black);
        text-decoration: none;
        transition: 0.3s;

        &:hover {
            opacity: 0.6;
        }
    }

    &__box {
        display: flex;
        align-items: center;
        gap: 12px;

        @include before_480 {
            justify-content: space-between;
        }
    }

    &__mobile-menu {
        display: none;
        padding-top: 10px;
    }

    &__link-logo {
        @include before_480 {
            margin: 0 auto 12px;
        }
    }
}

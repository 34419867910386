@import '../../../assets/global-style/mixin';

.wrapper {
    width: 100%;
    max-width: 1540px;
    padding: 0 50px;
    margin: 0 auto;
    box-sizing: border-box;

    @include before_768 {
        padding: 0 20px;
    }
}

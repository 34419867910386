@import '../../../assets/global-style/mixin';

.footer {
    &__top {
        padding: 30px 0;
        background-color: var(--color-blue);
    }

    &__bottom {
        padding: 50px 0;
        background-color: var(--color-black);
    }

    &__top-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include before_1024 {
            flex-direction: column;
            gap: 24px;
        }
    }

    &__bottom-content {
        display: flex;
        align-items: center;
        gap: 45px;
        justify-content: space-between;

        @include before_1024 {
            flex-direction: column;
            gap: 24px;
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        gap: 40px;
    }

    &__menu-list {
        display: flex;
        align-items: center;
        gap: 75px;

        @include before_1366 {
            gap: 24px;
        }

        @include before_768 {
            flex-direction: column;
        }
    }

    &__menu-link {
        font-size: 24px;
        font-weight: 500;
        color: var(--color-white);
        text-decoration: none;
        transition: 0.3s;
        text-transform: uppercase;

        @include before_1200 {
            font-size: 18px;
        }

        &:hover {
            opacity: 0.6;
        }
    }

    &__copyright {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-white);
        text-transform: uppercase;

        @include before_1024 {
            text-align: center;
        }
    }

    .contacts {
        @include before_768 {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__contacts-box {
        display: flex;
        align-items: baseline;
        gap: 30px;

        @include before_768 {
            flex-direction: column;
            align-items: center;
        }
    }

    &__phone {
        font-size: 24px;
        font-weight: 500;
        color: var(--color-white);
        text-transform: uppercase;
        text-decoration: none;

        @include before_1200 {
            font-size: 18px;
        }
    }

    &__email {
        font-size: 24px;
        font-weight: 500;
        color: var(--color-white);
        text-decoration: none;

        @include before_1200 {
            font-size: 18px;
        }
    }

    &__text {
        color: var(--color-white);
    }
}
